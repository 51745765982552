import React from "react";

class NetworkSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mdp: props.mdp,
      dsp: props.dsp,
      status: props.status,
    };
  }

  render() {
    return (
      <div class="col-md-6">
        <label class="form-label px-2">
          {this.props.name} - {this.props.status}
        </label>

        <input
          type="checkbox"
          id={this.props.name + "status"}
          name={this.props.name + "status"}
          value="active"
          onClick={(e) => {
            this.props.onSwitch(this.props.id, this.props.status);
          }}
          checked={this.props.status === "active" ? true : false}
        ></input>

        <div class="my-2">
          <label class="form-label px-2">Default Selling Price</label>
          <input
            type="text"
            name="dsp"
            placeholder="Default Selling Price"
            class="form-control"
            value={this.props.dsp == 0 ? "" : this.props.dsp}
            onChange={(e) => {
              this.props.handleChange(e, this.props.id, "DSP");
            }}
            disabled={this.props.status !== "active" ? true : false}
          />
        </div>
        <div class="my-2">
          <label class="form-label px-2">Minimum Discount Price</label>
          <input
            type="text"
            name="MDP"
            placeholder="Minimum Discount Price"
            class="form-control "
            value={this.props.mdp == 0 ? "" : this.props.mdp}
            onChange={(e) => {
              this.props.handleChange(e, this.props.id, "MDP");
            }}
            disabled={this.props.status !== "active" ? true : false}
          />
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.setState({});
  }
}

export default NetworkSwitch;
