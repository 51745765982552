import React from "react";
import cookie from "react-cookies";

class Voucheritem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dealerId: cookie.load("dealerId"),
      pin: props.pin,
      date: new Date().toLocaleDateString(),
    };

    //bind
    this.convertStringToArray = this.convertStringToArray.bind(this);
    this.convertToSpacedPin = this.convertToSpacedPin.bind(this);
  }

  convertStringToArray(str, maxPartSize) {
    const chunkArr = [];
    let leftStr = str;
    do {
      chunkArr.push(leftStr.substring(0, maxPartSize));
      leftStr = leftStr.substring(maxPartSize, leftStr.length);
    } while (leftStr.length > 0);

    return chunkArr;
  }

  convertToSpacedPin() {
    let str = this.convertStringToArray(this.state.pin, 6).join("-");
    return str;
  }

  render() {
    return (
      <div
        className="col-md-2 card mx-1 py-1 px-2 "
        style={{
          border: "1px solid black",
          maxHeight: this.props.network === "MTN" ? "2.99cm" : "2.99cm",
          minHeight: this.props.network === "MTN" ? "2.99cm" : "2.99cm",
          lineHeight: 1.3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          maxWidth:
            this.props.unitAmount == 100 || this.props.unitAmount == 200
              ? 208 + "px"
              : 419 + "px",
          minWidth:
            this.props.unitAmount == 100 || this.props.unitAmount == 200
              ? 208 + "px"
              : 419 + "px",
          marginBottom: 2 + "px",
          color: "black",
          fontWeight: "500",
          fontSize: 0.6 + "rem",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            fontSize: 0.55 + "rem",
          }}
        >
          {this.props.businessName.substring(0, 27)}{" "}
          {this.props.orderDescription}-{this.props.id + 1}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: 100 + "%",
            paddingRight:
              this.props.unitAmount == 100 || this.props.unitAmount == 200
                ? 32 + "px"
                : 120 + "px",
          }}
        >
          <div> Logical ({this.props.network})</div>
          <div>
            &#8358;
            {this.props.unitAmount}
          </div>
        </div>
        <div>
          <div>
            {this.props.network === "MTN"
              ? "S/Q: 000000" + this.props.sequence
              : ""}
          </div>
          <div>
            S/N:{" "}
            {this.props.network === "MTN"
              ? "000000" + this.props.serial
              : this.props.serial}
          </div>
          <div
            style={{
              fontSize:
                this.props.unitAmount == 100 || this.props.unitAmount == 200
                  ? 0.72 + "rem"
                  : 0.76 + "rem",
              letterSpacing:
                this.props.unitAmount == 100 || this.props.unitAmount == 200
                  ? "0.03rem"
                  : "0.17rem",
            }}
          >
            Pin:
            <span style={{ fontWeight: "900" }}>
              {this.convertToSpacedPin()}
            </span>
          </div>
        </div>
        <div style={{ fontSize: 0.55 + "rem", color: "black" }}>
          Date: {this.state.date}
        </div>

        <div class="" style={{ fontSize: 0.53 + "rem", color: "black" }}>
          Dial{" "}
          {this.props.network === "MTN"
            ? "*555*PIN#"
            : this.props.network === "AIRTEL"
            ? "*126*PIN#"
            : ""}{" "}
          to recharge
        </div>
        <div
          class=""
          style={{
            fontWeight: "bold",
            textAlign: "left",
          }}
        >
          <span class="">Powered by</span> TopUpBox
        </div>
        <img
          src={"assets/images/" + this.props.network + ".png"}
          alt=""
          style={{
            position: "absolute",
            right: 0 + "px",
            top: 0 + "px",
            height: "100%",
            width:
              this.props.unitAmount == 100 || this.props.unitAmount == 200
                ? "20px"
                : "100px",
            opacity: 0.5,
            zIndex: 0,
          }}
        />
      </div>
    );
  }

  componentDidMount() {}
}

export default Voucheritem;
