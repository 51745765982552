import React from "react";
import { ToastContainer, toast } from "react-toastify";
class SuccessPage extends React.Component {
  constructor() {
    super();
    this.state = {
      someKey: "someValue",
    };
  }

  render() {
    return (
      <div class="">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <main class="authentication-content">
          <div class="container-fluid">
            <div class="authentication-card">
              <div class="card shadow rounded-0 overflow-hidden">
                <div class="row g-0">
                  <div class="col-lg-6 bg-login d-flex align-items-center justify-content-center">
                    <img
                      src="assets/images/error/login-img.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body p-4 p-psm-5">
                      <div class="text-center mb-4"></div>
                      <h5 class="card-title text-center">Completed SignUp</h5>
                      <p>
                        <img
                          src="assets/images/check.png"
                          class="img-fluid"
                          alt=""
                        />
                      </p>
                      <p class="text-center">
                        <a
                          href="/signIn"
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          Kindly head to login here
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default SuccessPage;
