import React from 'react';
import Header from '../unified/header';
import SideBar from '../unified/SideBar';
import {
    BrowserRouter as Router,
    Routes as Switch,
    Route,
} from "react-router-dom";
import Main from './components/main';
import Transaction from "./components/transaction";
import CreateUser from '../logical/components/CreateUser';
import WalletHistory from './components/WalletHistory';
import Support from '../logical/components/support';
import Users from '../logical/components/users';
import Profile from '../logical/components/profile';
import MtnAxis from './components/mtnAxis';
import Compliance from '../logical/components/compliance';
import ManageCommision from './components/manageCommission';
import FundWalletReqHistory from '../logical/components/fundWalletReqHistory';
import ManageMerchants from './components/manageMerchants';
import Fund from './components/fund';
import MakeFund from './components/makeFund';
import FundWalletReq from './components/fundWalletReq';
import AddMtnAxis from './components/addmtnAxis';
import SetThreshold from '../unicard/components/SetThreshold';
import DownloadHistory from './components/downloadHistory';
import CreateBucket from './components/createBucket';
import AssignBucket from './components/AssignBucket';
import TransactionBucket from './components/transactionBuckrt';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            someKey: 'someValue'
        };
    }

    componentDidMount() {
        this.setState({
            someKey: 'otherValue'
        });
    }

    render() {
        return (<div>
            <Header {...this.props} activeProduct={this.props.activeProduct} setActiveProduct={this.props.setActiveProduct}></Header>
            <SideBar {...this.props} product={this.props.activeProduct} setActiveProduct={this.props.setActiveProduct}></SideBar>
            <Switch>
                <Route
                    exact
                    path="/"
                    key={1}
                    element={<Main {...this.props}></Main>}
                />
                <Route
                    exact
                    key={2}
                    path="/transactions"
                    element={<Transaction {...this.props}></Transaction>}
                />
                <Route
                    exact
                    key={222}
                    path="/download/history"
                    element={<DownloadHistory {...this.props}></DownloadHistory>}
                />
                <Route
                    exact
                    key={3}
                    path="/profile"
                    element={<Profile {...this.props}></Profile>}
                />
                <Route
                    exact
                    key={4}
                    path="/users"
                    element={<Users {...this.props}></Users>}
                />

                <Route
                    exact
                    key={5}
                    path="/fund"
                    element={<MakeFund {...this.props}></MakeFund>}
                />
                <Route
                    exact
                    key={6}
                    path="/support"
                    element={<Support {...this.props}></Support>}
                />
                <Route
                    exact
                    key={7}
                    path="/wallet-history"
                    element={<WalletHistory {...this.props}></WalletHistory>}
                />
                <Route
                    exact
                    key={9}
                    path="/fund-wallet-req"
                    element={<FundWalletReq {...this.props}></FundWalletReq>}
                />
                <Route
                    exact
                    key={10}
                    path="/req-history"
                    element={<FundWalletReqHistory></FundWalletReqHistory>}
                />
                <Route
                    exact
                    key={12}
                    path="/create-users"
                    element={<CreateUser {...this.props}></CreateUser>}
                />
                <Route
                    exact
                    key={24}
                    path="/invite-merchant"
                    element={<CreateUser {...this.props}></CreateUser>}
                />
                <Route
                    exact
                    key={16}
                    path="/add-mtn-axis"
                    element={<AddMtnAxis {...this.props} />}
                />
                <Route
                    exact
                    key={13}
                    path="/mtn-axis"
                    element={<MtnAxis {...this.props} />}
                />
                <Route
                    exact
                    key={14}
                    path="/manage-commissions"
                    element={<ManageCommision {...this.props} />}
                />
                <Route
                    exact
                    key={14}
                    path="/manage-merchants"
                    element={<ManageMerchants {...this.props} />}
                />
                <Route
                    exact
                    key={15}
                    path="/make-fund"
                    element={<MakeFund {...this.props} ></MakeFund>}
                />
                 <Route
                    exact
                    key={15}
                    path="/fund-merchant"
                    element={<Fund {...this.props} ></Fund>}
                />
                <Route
                    exact
                    key={17}
                    path="/system-config"
                    element={<Compliance walletBalance={this.state.walletBalance} {...this.props} />}
                />
                <Route
                    exact
                    key={25}
                    path="/set-threshold"
                    element={<SetThreshold {...this.props}></SetThreshold>}
                />
                <Route
                    exact
                    key={699}
                    path="/create-bucket"
                    element={<CreateBucket {...this.props}></CreateBucket>}
                />
                <Route
                    exact
                    key={698}
                    path="/assign-bucket"
                    element={<AssignBucket {...this.props}></AssignBucket>}
                />
                 <Route
                    exact
                    key={697}
                    path="/bucket-transaction"
                    element={<TransactionBucket {...this.props}></TransactionBucket>}
                />
            </Switch>
        </div >);
    }


}

export default Index;
