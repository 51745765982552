import React from "react";

class Security extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      privateKey: "",
    };
  }

  render() {
    return (
      <div className="py-3">
        <form class="row g-2">
          <h4>Update Private Key</h4>
          <div class="col-6">
            <label class="form-label">Private Key</label>
            <input
              type="text"
              class="form-control"
              value={this.props.privateKey}
              onChange={(e) => this.props.setPrivateKey(e)}
            />
          </div>
        </form>
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default Security;
