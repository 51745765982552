import axios from "axios";
import React, { useState, useEffect } from "react";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import { SME_WEB_BASE_URL, WEB_BASE_URL } from "../../unified/webbase";

const AssignBucket = (props) => {
  const [walletBalance, setWalletBalance] = useState(cookie.load("walletBalance"));
  const [token, setToken] = useState(cookie.load("token"));
//   const [dealerId, setDealerId] = useState("");
//   const [userRole, setUserRole] = useState(cookie.load("userRole"));
//   const [userGroup, setUserGroup] = useState(cookie.load("userGroup"));
  const [bucketId, setBucketId] = useState("");
  const [merchantEmail, setMerchantEmail] = useState("");
  const [bucket, setBucket] = useState([]);
  const [merchant, setMerchant] = useState([]);
  

  useEffect(() => {
    axios
    .post(WEB_BASE_URL + "/onboarding/all-merchants", {

    }, {
      headers: { authorization: token },
    })
    .then((res) => {
      if (res.data.status === "2000") {
        setMerchant(res.data.data.data)
      }
    })
    .catch((err) => {
      console.log(err);
    });


  }, [token]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    axios
    .get(SME_WEB_BASE_URL + "/glowsme/get-buckets", {
      headers: { authorization: token },
    })
    .then((res) => {
      if (res.data.status === "2000") {
        setBucket(res.data.data)
      }
    })
    .catch((err) => {
      console.log(err);
    });


  }, [token]);

  
 

  const createBucket = (event) => {
    const id = toast.loading("Please wait...");
    event.preventDefault();


      const user = {
      "bucketId": bucketId,
       "merchantEmail": merchantEmail
      
      };

      axios
        .post(SME_WEB_BASE_URL + "/glowsme/assign-bucket", user, {
          headers: { authorization: token },
        })
        .then((res) => {
          if (res.data.status === "2000") {
            toast.update(id, {
              render: res.data.description,
              type: "success",
              isLoading: false,
            });
            setTimeout(() => {
                window.location.reload()
            }, 1000);
          } else {
            toast.update(id, {
              render: "" + res.data.description,
              type: "error",
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          toast.update(id, {
            render: "" + err,
            type: "error",
            isLoading: false,
          });
        });
    } 
  

  return (
    <main className="page-content">
      <ToastContainer />
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt"></i>
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Assign SME Bucket
              </li>
            </ol>
          </nav>
        </div>
        <div className="ms-auto">
          <div className="btn-group">
            <button type="button" className="btn btn-primary">
              Wallet Balance: &#8358;{walletBalance}
            </button>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <form className="row" onSubmit={createBucket}>
            <div className="row py-2">
              <div className="col-6">
                <p className="form-label">Bucket </p>
                <select className=" form-select  " value={bucketId} required onChange={(e) => setBucketId(e.target.value)}>
                  <option>Select bucket </option>
                  {bucket.map((e,i) =>{
                   return  <option value={e.dealerId} key={i}>{e.dealerId} </option>
                  })}
                </select>
              </div>
              <div className="col-6">
                <p className="form-label">Merchant</p>
                
                <select className=" form-select  "  value={merchantEmail} required onChange={(e) => setMerchantEmail(e.target.value)}>
                  <option>Select Merchant </option>
                  {merchant.map((e,i) =>{
                   return  <option value={e.businessEmail} key={i}>{e.businessName} </option>
                  })}
                </select>
              </div>
            </div>
           

            <div className="col-4">
              <button type="submit" style={{ marginTop: 2 }} className="btn btn-primary d-flex">
                <div>
                  <i className="bi bi-cloud-arrow-down-fill"></i>
                </div>
                <div className="mx-3">Assign Bucket</div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default AssignBucket;
