import React from "react";
import cookie from "react-cookies";

class PrintModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: props.history,
      token: cookie.load("token"),
      transactionPin: "",
    };
    //binding
  }

  render() {
    return (
      <div
        className="modal fade"
        id={this.props.id}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.props.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                className="row g-3 "
                onSubmit={(e) => {
                  e.preventDefault();
                  this.props.onSubmit(
                    this.props.batchId,
                    this.props.description
                  );
                }}
              >
                <div className="col-12">
                  <label className="form-label">Voucher Description</label>
                  <input
                    type="email"
                    className="form-control"
                    value={this.props.description}
                    disabled={true}
                    required={true}
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">Number of Units</label>
                  <input
                    type="number"
                    className="form-control"
                    value={this.props.units}
                    required={true}
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">Authenticator Code</label>
                  <input
                    type="password"
                    className="form-control"
                    autoComplete="new-password"
                    value={this.props.transactionPin}
                    onChange={(e) => {
                      this.props.setPin(e);
                    }}
                    required={true}
                  />
                </div>

                <button type="submit" className="btn btn-primary">
                  Print
                </button>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {}
}

export default PrintModal;
