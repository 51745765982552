import React from "react";

class Reportdetails extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {};
  }

  render() {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return (
      <div
        className="modal fade"
        id={this.props.id}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.props.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                {this.props.networks.map((network) => (
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        Total Sales: {network.name}
                        <h4>
                          {formatter.format(this.props["Sold" + network.name])}
                        </h4>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div class="table-responsive">
                <table class="table align-middle mb-0">
                  <thead class="table-light">
                    <tr>
                      <th>Description</th>
                      <th>Units Sold</th>
                      <th>Denomination</th>
                      <th>Network</th>
                      <th>Selling Price</th>
                      <th>Total</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.vouchers.reverse().map((voucher) => (
                      <tr key={voucher.id}>
                        <td>{voucher.orderDescription}</td>
                        <td>{voucher.units}</td>
                        <td>&#8358;{voucher.unitAmount}</td>
                        <td>{voucher.network}</td>
                        <td>&#8358;{voucher.sellingPrice}</td>
                        <td>{formatter.format(voucher.totalAmount)}</td>
                        <td>
                          {voucher.createdDate
                            ? voucher.createdDate.split("T")[0]
                            : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.setState({});
  }
}

export default Reportdetails;
